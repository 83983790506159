export default defineNuxtRouteMiddleware(async (to, from) => {
  console.log('login-not-required')
  const { accessToken } = useAuthToken()

  if (accessToken.value) {
    const authStore = useAuthStore()
    const { getMyProfile } = authStore
    const { hasProfile, isRequriedInit } = storeToRefs(authStore)
    if (isRequriedInit.value) {
      return navigateTo('/init')
    } else if (hasProfile.value === false) {
      await getMyProfile()
    } else {
      return navigateTo('/ ')
    }
  }
})
